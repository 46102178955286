import React from "react";
import { IconButton, Modal } from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import moment from "moment/moment";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import UpdateIcon from "@mui/icons-material/Update";
import ClassIcon from "@mui/icons-material/Class";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PlaceIcon from "@mui/icons-material/Place";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import HomeIcon from "@mui/icons-material/Home";
import SourceIcon from "@mui/icons-material/Source";
import ContactsIcon from "@mui/icons-material/Contacts";
import EngineeringIcon from "@mui/icons-material/Engineering";
import ScoreIcon from "@mui/icons-material/Score";
import StoreIcon from "@mui/icons-material/Store";

const ViewLead = ({ viewModal, setViewModal, details }) => {
  return (
    <>
      <Modal
        open={viewModal}
        onClose={() => setViewModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "500px",
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          {details !== null ? (
            <>
              <div className="d-flex align-items-center justify-content-between">
                <p>Lead details</p>
                <IconButton
                  aria-label="close"
                  onClick={() => setViewModal(false)}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <>
                {details.category ? (
                  <p className="mb-2">
                    <ClassIcon />
                    Category :{" "}
                    <span className="fw-bold">{details.category}</span>
                  </p>
                ) : (
                  ""
                )}
                {details.tag ? (
                  <p className="mb-2">
                    <LocalOfferIcon />
                    Tag : <span className="fw-bold">{details.tag}</span>
                  </p>
                ) : (
                  ""
                )}
                {details.name ? (
                  <p className="mb-2">
                    <PersonIcon />
                    Name : <span className="fw-bold">{details.name}</span>
                  </p>
                ) : (
                  ""
                )}
                {details.email ? (
                  <p className="mb-2">
                    <EmailIcon />
                    Email : <span className="fw-bold">{details.email}</span>
                  </p>
                ) : (
                  ""
                )}
                {details.phone1 ? (
                  <p className="mb-2">
                    <PhoneIcon />
                    Phone 1 : <span className="fw-bold">{details.phone1}</span>
                  </p>
                ) : (
                  ""
                )}
                {details.phone2 ? (
                  <p className="mb-2">
                    <PhoneIcon />
                    Phone 2 : <span className="fw-bold">{details.phone2}</span>
                  </p>
                ) : (
                  ""
                )}
                {details.phone3 ? (
                  <p className="mb-2">
                    <PhoneIcon />
                    Phone 3 : <span className="fw-bold">{details.phone3}</span>
                  </p>
                ) : (
                  ""
                )}
                {details.whatsapp ? (
                  <p className="mb-2">
                    <WhatsAppIcon />
                    Whatsapp :{" "}
                    <span className="fw-bold">{details.whatsapp}</span>
                  </p>
                ) : (
                  ""
                )}
                {details.city ? (
                  <p className="mb-2">
                    <LocationCityIcon />
                    City : <span className="fw-bold">{details.city}</span>
                  </p>
                ) : (
                  ""
                )}
                {details.pincode ? (
                  <p className="mb-2">
                    <PlaceIcon />
                    Pincode : <span className="fw-bold">{details.pincode}</span>
                  </p>
                ) : (
                  ""
                )}
                {details.address1 ? (
                  <p className="mb-2">
                    <HomeIcon />
                    Address 1 :{" "}
                    <span className="fw-bold">{details.address1}</span>
                  </p>
                ) : (
                  ""
                )}
                {details.address2 ? (
                  <p className="mb-2">
                    <HomeIcon />
                    Address 2 :{" "}
                    <span className="fw-bold">{details.address2}</span>
                  </p>
                ) : (
                  ""
                )}
                {details.address3 ? (
                  <p className="mb-2">
                    <HomeIcon />
                    Address 3 :{" "}
                    <span className="fw-bold">{details.address3}</span>
                  </p>
                ) : (
                  ""
                )}
                {details.source ? (
                  <p className="mb-2">
                    <SourceIcon />
                    Source : <span className="fw-bold">{details.source}</span>
                  </p>
                ) : (
                  ""
                )}
                {details.aadhar ? (
                  <p className="mb-2">
                    <ContactsIcon />
                    Aadhar : <span className="fw-bold">{details.aadhar}</span>
                  </p>
                ) : (
                  ""
                )}
                {details.pan ? (
                  <p className="mb-2">
                    <ContactsIcon />
                    PAN : <span className="fw-bold">{details.pan}</span>
                  </p>
                ) : (
                  ""
                )}
                {details.occupation ? (
                  <p className="mb-2">
                    <EngineeringIcon />
                    Occupation :{" "}
                    <span className="fw-bold">{details.occupation}</span>
                  </p>
                ) : (
                  ""
                )}
                {details.homeOwner ? (
                  <p className="mb-2">
                    <HomeIcon />
                    Home owner :{" "}
                    <span className="fw-bold">{details.homeOwner}</span>
                  </p>
                ) : (
                  ""
                )}
                {details.creditScore ? (
                  <p className="mb-2">
                    <ScoreIcon />
                    Credit score :{" "}
                    <span className="fw-bold">{details.creditScore}</span>
                  </p>
                ) : (
                  ""
                )}
                {details.companyName ? (
                  <p className="mb-2">
                    <StoreIcon />
                    Company name :{" "}
                    <span className="fw-bold">{details.companyName}</span>
                  </p>
                ) : (
                  ""
                )}
                {details.gst ? (
                  <p className="mb-2">
                    <ContactsIcon />
                    GST : <span className="fw-bold">{details.gst}</span>
                  </p>
                ) : (
                  ""
                )}
                {/*  */}
                {/*  */}
                {/*  */}
                <p className="mb-2">
                  <AccessTimeIcon />
                  Lead created at :{" "}
                  <span className="fw-bold">
                    {moment(details.createdAt).format(
                      "Do MMMM YYYY, h:mm:ss a"
                    )}
                  </span>
                </p>
                <p className="mb-2">
                  <UpdateIcon />
                  Lead updated at :{" "}
                  <span className="fw-bold">
                    {moment(details.updatedAt).format(
                      "Do MMMM YYYY, h:mm:ss a"
                    )}
                  </span>
                </p>
              </>
            </>
          ) : (
            ""
          )}
        </Box>
      </Modal>
    </>
  );
};

export default ViewLead;

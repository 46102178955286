import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./context/Theme";
import Home from "./pages/Home";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import Auth from "./utility/Auth";
import { AppContextProvider } from "./context/AppContext";
import Profile from "./pages/Profile";

const App = () => {
  return (
    <AppContextProvider>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Auth Component={Home} />} />
          <Route path="/profile" element={<Auth Component={Profile} />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </ThemeProvider>
    </AppContextProvider>
  );
};

export default App;

import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../context/AppContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ButtonGroup,
  IconButton,
  TextField,
  Button,
  Pagination,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Cookies from "js-cookie";
import axios from "axios";
import ViewLead from "../components/lead/ViewLead";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { FixedSizeList as List } from "react-window";
import ShowReportModal from "../components/lead report/ShowReportModal";
import TuneIcon from "@mui/icons-material/Tune";
import FilterModal from "../components/lead/FilterModal";

const Home = () => {
  const { url } = useContext(AppContext);
  //
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  //
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10000);
  const [totalCount, setTotalCount] = useState(0);
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  //
  const [searchInput, setSearchInput] = useState("");
  const [searchState, setSearchState] = useState(false);
  const startSearch = () => {
    setSearchState(true);
    getLeads();
  };
  const clear = () => {
    setSearchInput("");
    setSearchState(false);
  };
  //
  const [filterModal, setFilterModal] = useState(false);
  const [filterStatus, setFilterStatus] = useState(false);
  const [filterInputs, setFilterInputs] = useState({
    category: "",
    tag: "",
    source: "",
    city: "",
    state: "",
  });
  const handleFilterInputs = (e) => {
    const { name, value } = e.target;
    setFilterInputs({
      ...filterInputs,
      [name]: value,
    });
  };
  const clearFilter = () => {
    if (
      filterInputs.category == "" &&
      filterInputs.tag == "" &&
      filterInputs.source == "" &&
      filterInputs.city == "" &&
      filterInputs.state == ""
    ) {
      setFilterModal(false);
    } else {
      setFilterInputs({
        category: "",
        tag: "",
        source: "",
        city: "",
        state: "",
      });
      setFilterModal(false);
    }
  };
  //
  const getLeads = async () => {
    try {
      setLoading(true);
      setFilterStatus(true);
      const dm_user = Cookies.get("dm_user");
      //
      const params = new URLSearchParams();
      params.append("page", page);
      params.append("pageSize", pageSize);
      //
      if (searchInput) params.append("keyword", searchInput);
      const { category, tag, source, city, state } = filterInputs;
      if (category) params.append("category", category);
      if (tag) params.append("tag", tag);
      if (source) params.append("source", tag);
      if (city) params.append("city", tag);
      if (state) params.append("state", tag);
      //
      const res = await axios.get(`${url}/leads/user?${params.toString()}`, {
        headers: {
          token: dm_user,
        },
      });
      if (res.data.success === true) {
        setLeads(res.data.result);
        setTotalCount(res.data.totalCount);
        setFilterModal(false);
        setLoading(false);
        setFilterStatus(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getLeads();
  }, [page, pageSize]);
  useEffect(() => {}, []);
  useEffect(() => {
    if (filterModal === false) {
      getLeads();
    }
  }, [filterInputs]);
  //
  const [viewModal, setViewModal] = useState(false);
  const [details, setDetails] = useState(null);
  const handleViewModal = (item) => {
    setViewModal(true);
    setDetails(item);
  };
  //
  const columns = [
    { label: "Sr no", width: 75 },
    { label: "Category", width: 200 },
    { label: "Tag", width: 200 },
    { label: "Name", width: 200 },
    { label: "Email", width: 200 },
    { label: "Phone 1", width: 200 },
    { label: "Phone 2", width: 200 },
    { label: "Phone 3", width: 200 },
    { label: "Whatsapp", width: 200 },
    { label: "State", width: 200 },
    { label: "City", width: 200 },
    { label: "PIN code", width: 200 },
    { label: "Address 1", width: 200 },
    { label: "Address 2", width: 200 },
    { label: "Address 3", width: 200 },
    { label: "Source", width: 200 },
    { label: "Aadhar", width: 200 },
    { label: "PAN", width: 200 },
    { label: "Occupation", width: 200 },
    { label: "Home owner", width: 200 },
    { label: "Credit score", width: 200 },
    { label: "Company name", width: 200 },
    { label: "GST", width: 200 },
    { label: "Call status", width: 200 },
    { label: "SMS status", width: 200 },
    { label: "Email status", width: 200 },
    { label: "Whatsapp status", width: 200 },
  ];
  const Row = ({ index, style }) => {
    const item = leads[index];
    return (
      <TableRow
        style={{ ...style, display: "flex", overflowX: "hidden" }}
        key={item.id}
      >
        <TableCell className="border" sx={{ minWidth: 75, flex: "1 1 auto" }}>
          {index + 1}
        </TableCell>
        {columns.slice(1).map((column, colIndex) => (
          <TableCell
            key={colIndex + 1}
            sx={{ minWidth: column.width, flex: "1 1 auto" }}
            className="border text-clamp"
          >
            {item[Object.keys(item)[colIndex + 1]]}
          </TableCell>
        ))}
        <TableCell className="border" sx={{ minWidth: "200px" }}>
          <ButtonGroup>
            <IconButton color="primary" onClick={() => handleViewModal(item)}>
              <VisibilityIcon />
            </IconButton>
          </ButtonGroup>
        </TableCell>
      </TableRow>
    );
  };
  //
  const [report, setReport] = useState(null);
  const [showReport, setShowReport] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);
  const getReport = async () => {
    setShowReport(true);
    setReportLoading(true);
    const dm_user = Cookies.get("dm_user");
    try {
      const res = await axios.get(`${url}/report/user`, {
        headers: {
          token: dm_user,
        },
      });
      if (res.data.success === true) {
        setReport(res.data.result);
        setReportLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="container my-2">
        {loading ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            <div className="row align-items-end justify-content-between gap-md-0 gap-2">
              <div className="col-md-6">
                <div className="d-flex align-items-end justify-content-end gap-2">
                  <TextField
                    label="Search"
                    variant="standard"
                    fullWidth
                    type="text"
                    margin="normal"
                    name="customerName"
                    autoComplete="off"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                  {searchInput !== "" ? (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={startSearch}
                      >
                        Search
                      </Button>
                    </>
                  ) : (
                    ""
                  )}
                  {searchState === true ? (
                    <Button
                      variant="contained"
                      color="danger"
                      className="text-white"
                      onClick={clear}
                    >
                      Clear
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex align-items-center justify-content-end gap-2">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={getReport}
                  >
                    Report
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => setFilterModal(true)}
                    endIcon={<TuneIcon />}
                  >
                    Filter
                  </Button>
                </div>
              </div>
            </div>

            <p className="mb-2">
              Total leads : <span className="fw-bold">{totalCount}</span>
            </p>
            {/*  */}
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow
                    className="table-background"
                    style={{ display: "flex" }}
                  >
                    {columns.map((item, index) => (
                      <TableCell
                        key={index}
                        className="border"
                        sx={{ minWidth: item.width, flex: "1 1 auto" }}
                      >
                        {item.label}
                      </TableCell>
                    ))}
                    <TableCell
                      className="border text-end"
                      sx={{ minWidth: "200px" }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <List
                    width="100%"
                    height={400}
                    itemCount={leads.length}
                    itemSize={100}
                    outerElementType={React.forwardRef((props, ref) => (
                      <div
                        ref={ref}
                        {...props}
                        style={{ ...props.style, overflowX: "auto" }}
                      />
                    ))}
                  >
                    {({ index, style }) => <Row index={index} style={style} />}
                  </List>
                </TableBody>
              </Table>
            </TableContainer>
            <div className="mt-2">
              <Pagination
                count={Math.ceil(totalCount / pageSize)}
                page={page}
                onChange={handlePageChange}
              />
            </div>
          </>
        )}
      </div>
      {/*  */}
      <ViewLead
        viewModal={viewModal}
        setViewModal={setViewModal}
        details={details}
      />
      {/*  */}
      <ShowReportModal
        report={report}
        showReport={showReport}
        setShowReport={setShowReport}
        reportLoading={reportLoading}
      />
      {/*  */}
      <FilterModal
        filterModal={filterModal}
        setFilterModal={setFilterModal}
        filterInputs={filterInputs}
        handleFilterInputs={handleFilterInputs}
        getLeads={getLeads}
        clearFilter={clearFilter}
        setPage={setPage}
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
      />
    </>
  );
};

export default Home;

import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const Loader = () => {
  return (
    <div className="d-flex align-items-center justify-content-center gap-2 my-4">
      <p>Loading</p>
      <Box>
        <CircularProgress size={20} />
      </Box>
    </div>
  );
};

export default Loader;

import React from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";

const FilterModal = ({
  filterModal,
  setFilterModal,
  filterInputs,
  handleFilterInputs,
  getLeads,
  clearFilter,
  setPage,
  filterStatus,
}) => {
  const startFilter = () => {
    if (
      filterInputs.category == "" &&
      filterInputs.tag == "" &&
      filterInputs.source == "" &&
      filterInputs.city == "" &&
      filterInputs.state == ""
    ) {
      setFilterModal(false);
    } else {
      setPage(1);
      getLeads();
    }
  };
  return (
    <>
      <Modal
        open={filterModal}
        onClose={() => setFilterModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 300,
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          <div>
            <div className="d-flex align-items-center justify-content-between">
              <p>Filter</p>
              <IconButton
                aria-label="close"
                onClick={() => setFilterModal(false)}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <FormControl
              variant="standard"
              sx={{ width: "100%", marginBottom: "20px" }}
            >
              <InputLabel id="demo-simple-select-standard-label">
                Category
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={filterInputs.category}
                onChange={handleFilterInputs}
                label="Category"
                name="category"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="GST">GST</MenuItem>
                <MenuItem value="SD">SD</MenuItem>
                <MenuItem value="TCM">TCM</MenuItem>
                <MenuItem value="SML">SML</MenuItem>
              </Select>
            </FormControl>
            {/*  */}
            <FormControl
              variant="standard"
              sx={{ width: "100%", marginBottom: "20px" }}
            >
              <InputLabel id="demo-simple-select-standard-label">
                Tag
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={filterInputs.tag}
                onChange={handleFilterInputs}
                label="tag"
                name="tag"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="option1">Ten</MenuItem>
                <MenuItem value="option2">Twenty</MenuItem>
                <MenuItem value="option3">Thirty</MenuItem>
              </Select>
            </FormControl>
            {/*  */}
            <FormControl
              variant="standard"
              sx={{ width: "100%", marginBottom: "20px" }}
            >
              <InputLabel id="demo-simple-select-standard-label">
                Source
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={filterInputs.source}
                onChange={handleFilterInputs}
                label="source"
                name="source"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="option1">Ten</MenuItem>
                <MenuItem value="option2">Twenty</MenuItem>
                <MenuItem value="option3">Thirty</MenuItem>
              </Select>
            </FormControl>
            {/*  */}
            <FormControl
              variant="standard"
              sx={{ width: "100%", marginBottom: "20px" }}
            >
              <InputLabel id="demo-simple-select-standard-label">
                City
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={filterInputs.city}
                onChange={handleFilterInputs}
                label="city"
                name="city"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="option1">Ten</MenuItem>
                <MenuItem value="option2">Twenty</MenuItem>
                <MenuItem value="option3">Thirty</MenuItem>
              </Select>
            </FormControl>
            {/*  */}
            <FormControl
              variant="standard"
              sx={{ width: "100%", marginBottom: "20px" }}
            >
              <InputLabel id="demo-simple-select-standard-label">
                State
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={filterInputs.state}
                onChange={handleFilterInputs}
                label="state"
                name="state"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="option1">Ten</MenuItem>
                <MenuItem value="option2">Twenty</MenuItem>
                <MenuItem value="option3">Thirty</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="d-flex align-items-center justify-content-end gap-2">
            {filterStatus === false ? (
              <Button
                color="danger"
                variant="contained"
                className="text-white"
                onClick={clearFilter}
              >
                Clear
              </Button>
            ) : (
              ""
            )}
            <Button
              color="primary"
              variant="contained"
              className="text-white"
              onClick={startFilter}
            >
              Applying filter
              {filterStatus === true ? (
                <CircularProgress
                  size={20}
                  sx={{ color: "white", marginLeft: "20px" }}
                />
              ) : (
                ""
              )}
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default FilterModal;

import React from "react";
import { Button, Modal } from "@mui/material";
import { Box } from "@mui/system";
import Loader from "../Loader";

const ShowReportModal = ({
  report,
  showReport,
  setShowReport,
  reportLoading,
}) => {
  return (
    <>
      <Modal
        open={showReport}
        onClose={() => setShowReport(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 300,
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          {reportLoading ? (
            <Loader />
          ) : report !== null ? (
            <>
              <p>
                Category : <span className="fw-bold">{report.category}</span>
              </p>
              <p>
                Tag : <span className="fw-bold">{report.tag}</span>
              </p>
              <p>
                State : <span className="fw-bold">{report.state}</span>
              </p>
              <p>
                City : <span className="fw-bold">{report.city}</span>
              </p>
              <p>
                PIN code : <span className="fw-bold">{report.pincode}</span>
              </p>
              <p>
                Source : <span className="fw-bold">{report.source}</span>
              </p>
            </>
          ) : (
            ""
          )}

          <div className="d-flex align-items-center justify-content-end">
            <Button
              onClick={() => setShowReport(false)}
              color="primary"
              variant="contained"
              className="text-white"
            >
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ShowReportModal;

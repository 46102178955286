import { useContext, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import Navbar from "../components/Navbar";

const Auth = ({ Component }) => {
  const { url, setUser } = useContext(AppContext);
  const navigate = useNavigate();
  useEffect(() => {
    const dm_user = Cookies.get("dm_user");
    if (!dm_user) {
      navigate("/login", { replace: true });
      return;
    }
    //
    const getUser = async () => {
      const res = await axios.get(`${url}/user`, {
        headers: {
          token: dm_user,
        },
      });
      if (res.data.success === true) {
        setUser(res.data.result);
      }
    };
    getUser();
  }, []);
  return (
    <>
      <Navbar />
      <Component />
    </>
  );
};

export default Auth;

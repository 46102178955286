import React from "react";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { Button } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const { user } = useContext(AppContext);
  const navigate = useNavigate();
  const logout = () => {
    Cookies.remove("dm_user");
    navigate("/login", { replace: true });
  };
  return (
    <>
      <div className="container p-2">
        {user && (
          <div>
            <h2>
              <span className="fw-bold">Name</span>: {user.name}
            </h2>
            <h2>
              <span className="fw-bold">Mobile</span>: {user.mobile}
            </h2>
            <h2>
              <span className="fw-bold">Email</span>: {user.email}
            </h2>
          </div>
        )}
        <hr />
        <div className="d-flex align-items-center justify-content-start gap-2">
          <Button
            variant="contained"
            color="danger"
            className="text-white"
            endIcon={<LogoutIcon />}
            onClick={logout}
          >
            Logout
          </Button>
        </div>
      </div>
    </>
  );
};

export default Profile;
